import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content"];

  connect() {
    bulmaCalendar.attach(this.element, {
      dateFormat: 'YYYY-MM-DD',
      lang: "en",
      overlay: true,
    })
  }
}
