import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    selectedTab: String,
  };

  static targets = ["tab", "tabContent"];

  connect() {
    this.#setActiveTab();
  }

  changeTab(e) {
    this.selectedTabValue = e.params.tab;
    this.#setActiveTab();
  }

  #setActiveTab() {
    this.tabTargets.forEach((tab) => {
      if (tab.dataset.crewTab === this.selectedTabValue) {
        tab.classList.add("is-active");
      } else {
        tab.classList.remove("is-active");
      }
    });

    this.tabContentTargets.forEach((tabContent) => {
      if (tabContent.dataset.crewTab === this.selectedTabValue) {
        tabContent.classList.remove("is-hidden");
      } else {
        tabContent.classList.add("is-hidden");
      }
    });
  }
}
