import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["link"];

  navigateToLink(e) {
    if (e.target.href === this.linkTarget.href) return;

    if (e.shiftKey || e.ctrlKey || e.metaKey) {
      window.open(this.linkTarget.href, "_blank");
    } else {
      this.linkTarget.click();
    }
  }
}
